<template>
  <action :action="action" showText v-on="$listeners">
    <template v-slot:default="{ show }">
      <div v-if="show">
        <v-expand-transition>
          <div v-if="!event && action.choices" class="d-flex flex-row align-baseline" :class="noTimeSelected ? 'justify-center' : 'justify-end'">
            <v-dialog
              ref="menu"
              v-model="menu"
              content-class="schedule-dialog"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="600px"
              persistent
              :fullscreen="$vuetify.breakpoint.xsOnly"
              @keydown.esc="menu = false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" :class="{'primary-color' : noTimeSelected}" v-on="on">{{noTimeSelected ? 'Continue Submission'  : 'Edit'}}</v-btn>
              </template>
              <v-sheet color="white" class="d-flex flex-column">
                <div class="mx-4 my-2">
                  <div class="t-5 primary--text"><b>Select times for your session</b></div>
                  <div class="t-1">
                    Select a day from the left column and times from the right column. Times listed are in the
                    America/New_York timezone.
                  </div>
                  <v-btn id="dismissScheduleAction" icon absolute top right @click="menu=false"><v-icon>$close</v-icon></v-btn>
                </div>
                <v-divider />
                <div class="d-flex flex-row align-stretch" style="flex: 2; overflow-y: hidden">
                  <v-list
                    class="px-2"
                    :class="{
                      narrow: $vuetify.breakpoint.xsOnly,
                      notNarrow: $vuetify.breakpoint.smAndUp,
                    }"
                    style="overflow-y: auto"
                  >
                    <v-list-item-group v-model="selectedDay" color="primary">
                      <v-list-item v-for="(d, i) in days" :key="i" class="tp-list-item mb-4">
                        <template v-slot:default>
                          <v-list-item-content>
                            <v-list-item-title
                              class="d-flex"
                              :class="{ 'selected-day': d.times.length }"
                              style="font-weight: 300"
                            >
                              <div v-text="d.name" />
                              <v-spacer />
                              <div v-text="d.date" />
                            </v-list-item-title>
                            <v-list-item-subtitle
                              style="white-space: normal"
                              v-html="d.times.map((t) => t.replaceAll(' ', '&nbsp;')).join(', ')"
                            />
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-divider vertical class="mx-1" />
                  <v-list style="flex: 1; overflow-y: auto" class="pr-1" v-if="action.choices[selectedDay]">
                    <v-list-item-group
                      v-model="selectedDays"
                      multiple
                      style="overflow-y: auto"
                      active-class="selected-time"
                      color="primary"
                      @change="onTimeSelected"
                    >
                      <v-list-item v-for="(c, i) in currentDaysTimes" :key="i" :value="c.ts" class="tp-list-item">
                        <template v-slot:default>
                          <v-list-item-content>
                            <v-list-item-title v-text="c.time" class="text-center time-entry" />
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <div v-else class="d-flex flex-column align-center justify-center px-4" style="flex: 1">
                    <div class="t-4 text-center">Select a date on the left first</div>
                    <div class="t-2 text-center">Multiple days can be selected</div>
                  </div>
                </div>
                <v-divider xclass="my-1" />
                <div class="d-flex mx-4 my-2 align-center">
                  <div v-text="summary" class="text-body-2" />
                  <v-spacer />
                  <o-btn class="mr-4" id="cancelScheduleAction" text outlined @click="menu=false">CANCEL</o-btn>
                  <o-btn id="confirmScheduleAction" :disabled="!valid" @click="done">CONFIRM</o-btn>
                </div>
              </v-sheet>
            </v-dialog>
          </div>
        </v-expand-transition>
      </div>
    </template>
  </action>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { ChatBotAction, ChatBotChoice, ChatBotEvent } from "@/service";
import { AlertDialogOptions } from "@/plugins/alert";
import Action from "./Action.vue";
import OBtn from "@/framework/OBtn.vue";
import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";

const { mapGetters: mapRootGetters } = createHelpers<RootStoreInterface>();
const rootGetters = mapRootGetters(["isAllowedCSRAdmin", "isAllowedCSRView"]);

export default Vue.extend({
  name: "ScheduleActionComponent",
  components: {
    Action,
    OBtn,
  },
  props: {
    action: {
      type: Object as PropType<ChatBotAction>,
      required: true,
    },
  },
  data: () => ({
    event: undefined as ChatBotEvent | undefined,
    selected: [] as Array<Array<number>>, // Indexed against choices index
    menu: true,
    displayText: "Select available times",
    selectedDay: -1,
  }),
  computed: {
    ...rootGetters,
    minimum(): number {
      return this.action.minimum ?? 1;
    },
    maximum(): number {
      return this.action.maximum ?? 50;
    },
    valid(): boolean {
      // CSR is not restricted 
      if (this.isAllowedCSRView) {
        return true;
      }
      // Current selection meet criteria?
      // Fixed criteria - at least 2 days and at least 2 selections per day
      const validDays = this.selected.filter((day) => day.length >= 1);

      return (
        validDays.length >= 1 && this.selectedList.length >= this.minimum && this.selectedList.length <= this.maximum
      );
    },
    selectedList(): Array<number> {
      return this.selected.flat();
    },
    summary(): string {
      if (this.selectedList.length) {
        if (this.minimum > 1 && this.selectedList.length <= this.minimum) {
          return `${this.selectedList.length} of ${this.minimum} Times Selected.`;
        } else if (this.selectedList.length > this.maximum) {
          return `${this.selectedList.length} of ${this.minimum} Times Selected, but the limit is ${this.maximum}`;
        } else if (!this.valid) {
          // Combination
          return `Please select times on more days.`;
        }
        return `${this.selectedList.length} of Maximum ${this.maximum} Time${this.selectedList.length > 1 ? "s" : ""}`;
      } else {
        return "Please select your available times.";
      }
    },
    days(): Array<{ name: string; times: Array<string> }> {
      if (!this.action.choices) {
        return [];
      }
      const mobile = this.$vuetify.breakpoint.smAndDown;

      return this.action.choices.map((c: ChatBotChoice, i: number) => ({
        name: mobile ? c.value.short : c.value.long, //c.name,
        date: c.value.date,
        times: this.selected[i]
          ? (this.selected[i]
              .map((t) => {
                const timeData = this.findTimeValue(c, t);
                return timeData?.time ?? null;
              })
              .filter((x) => Boolean(x)) as string[])
          : [],
      }));
    },
    currentDaysTimes(): Array<{ ts: number; time: string }> {
      if (!this.action.choices || this.selectedDay < 0 || this.selectedDay >= this.action.choices.length) {
        return [];
      }
      return this.action.choices[this.selectedDay].value.times;
    },
    selectedDays: {
      get() {
        return this.selected[this.selectedDay] ?? [];
      },
      set(v: Array<number>) {
        if (this.selectedDay >= 0) {
          this.selected.splice(this.selectedDay, 1, v);
        }
      },
    },
    noTimeSelected(): boolean {
      return this.action.clientData.times || this.action.clientData?.times?.length ? false : true;
    }
  },
  methods: {
    done(): void {
      this.event = {
        type: "respond",
        ts: Date.now(),
        clientData: this.action.clientData,
        responses: this.selectedList.map((x) => String(x)),
      };
      this.$emit("callback", this.event);
    },
    findTimeValue(c: ChatBotChoice, ts: number): { time: string; ts: number } | undefined {
      return (c.value.times as Array<any>).find((x: { ts: number; time: string }) => x.ts === ts);
    },
    onTimeSelected(e: any | any[]) {      
      // Prevent selection if maximum limit is reached
      if (this.selectedList.length > this.maximum && Array.isArray(e)) {
        e.pop();
        this.showLimitWarning();
      } else {
        e.sort((x: number, y: number) => (x - y));
      }
    },
    showLimitWarning() {
      const options: AlertDialogOptions = {
        title: "Warning",
        dismissText: "Close",
        icon: "",
        dismissTextFlat: false
      };
      this.$alert("You have selected the maximum amount of times", options);
    }
  },
  watch: {
    action: {
      handler(v: ChatBotAction) {
        if (v.choices?.length) {
          // This form creates a new array object for each row
          this.selected = Array.from(Array(v.choices?.length), () => []);

          // Any pre-selected? add them to the correct list
          if (v.responses) {
            v.responses.forEach((t: string) => {
              const ts = Number(t);
              const c = v.choices?.findIndex((choice) => this.findTimeValue(choice, ts) !== undefined) ?? -1;
              if (c > -1) {
                this.selected[c].push(ts);
              }
            });
          }
        } else {
          this.selected = [];
        }
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.primary-color {
  color: var(--v-primary-base) !important;
}
.tp-list-item,
.tp-list-item::before {
  border-radius: 10px;
}

.action-buttons {
  display: flex;

  &.horizontal {
    flex-direction: row;
    justify-content: space-evenly;
  }
  &.vertical {
    flex-direction: column;
  }
}

.selected-day {
  font-weight: bold !important;
  color: var(--v-primary-base);
}
.time-entry {
  font-weight: 300;
}
.selected-time {
  font-weight: bold !important;
  &:before {
    opacity: 0;
  }

  .time-entry {
    font-weight: bold !important;
  }
}

.narrow {
  max-width: 60%;
}

.notNarrow {
  // max-width: 14rem;
  // width: 14rem;
  width: 23rem;
}
</style>
<style lang="scss">
.schedule-dialog {
  overflow-y: hidden;
  position: relative;
  display: flex;
  justify-content: stretch;
  align-content: stretch;

  max-height: 800px !important;
  height: 90%;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .schedule-dialog {
    height: 100%;
  }
}
</style>
