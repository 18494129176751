/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DiagnosisRecordItem } from './DiagnosisRecordItem';
import type { MembershipPlanRecordItem } from './MembershipPlanRecordItem';
import type { PoolRecordItem } from './PoolRecordItem';
import type { UserPartialItem } from './UserPartialItem';

/**
 * Query Header Input Item
 */
export type QueryHeaderInputItem = {
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * QueryHeader ID after creation
     */
    queryHeaderId?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    completed?: number;
    /**
     * Query Expert Input Source
     */
    source?: string;
    /**
     * User ID
     */
    userId?: number;
    /**
     * Created User ID
     */
    userIdCreated?: number;
    /**
     * User ID that Updated Query Input
     */
    updatedBy?: number;
    /**
     * Flag for active
     */
    active?: boolean;
    /**
     * Query type
     */
    type?: QueryHeaderInputItem.type;
    /**
     * Flag for show if it is Poster anonymous
     */
    anonUser?: boolean;
    /**
     * Query Header Input History
     */
    history?: string;
    /**
     * Query Header Input Text
     */
    text?: string;
    /**
     * Diagnosis ID
     */
    diagnosisId?: number;
    /**
     * Plan ID
     */
    planId?: number;
    /**
     * Grant ID
     */
    grantId?: number;
    /**
     * Optional - again plan/grant rule dependent
     */
    askerContext?: number;
    /**
     * Query Media Type
     */
    mediaType?: QueryHeaderInputItem.mediaType;
    /**
     * Gender
     */
    gender?: QueryHeaderInputItem.gender;
    dob?: string;
    /**
     * Query Header Input - Age Magnitude
     */
    ageMagnitude?: number;
    /**
     * Age Unit
     */
    ageUnit?: QueryHeaderInputItem.ageUnit;
    /**
     * List of schedule times
     */
    scheduleTimes?: string;
    /**
     * Pool ID
     */
    poolId?: number;
    /**
     * Expert Selection Mode
     */
    expertSelectionMode?: QueryHeaderInputItem.expertSelectionMode;
    /**
     * Draft Client Data
     */
    clientData?: string;
    /**
     * API Response Version
     */
    _ver?: number;
    pool?: PoolRecordItem;
    diagnosis?: DiagnosisRecordItem;
    plan?: MembershipPlanRecordItem;
    userOwner?: UserPartialItem;
    userCreated?: UserPartialItem;
    userUpdated?: UserPartialItem;
}

export namespace QueryHeaderInputItem {

    /**
     * Query type
     */
    export enum type {
        NORMAL = 'normal',
        BLINDED = 'blinded',
        COMMUNAL = 'communal',
        PRIVATE = 'private',
        VTB = 'vtb',
        EMO = 'emo',
    }

    /**
     * Query Media Type
     */
    export enum mediaType {
        MESSENGER = 'messenger',
        AUDIO = 'audio',
        VIDEO = 'video',
    }

    /**
     * Gender
     */
    export enum gender {
        UNSPECIFIED = 'unspecified',
        FEMALE = 'female',
        MALE = 'male',
        TRANSMALE = 'transmale',
        TRANSFEMALE = 'transfemale',
        PRETRANSMALE = 'pretransmale',
        PRETRANSFEMALE = 'pretransfemale',
        INDETERMINATE = 'indeterminate',
    }

    /**
     * Age Unit
     */
    export enum ageUnit {
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }

    /**
     * Expert Selection Mode
     */
    export enum expertSelectionMode {
        GRANT_FIRST = 'grant_first',
        PLAN_FIRST = 'plan_first',
        ALL = 'all',
        USER_CHOICE = 'user_choice',
        PLAN_ONLY = 'plan_only',
        GRANT_ONLY = 'grant_only',
        PLAN_SELECT = 'plan_select',
    }


}
