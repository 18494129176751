/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SurveyQuestionRecordItem } from './SurveyQuestionRecordItem';

/**
 * Survey Header Record Item
 */
export type SurveyHeaderRecordItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Header Owner ID
     */
    userId?: number;
    /**
     * Header Owner ID
     */
    updatedBy?: number;
    /**
     * Query Header ID
     */
    queryHeaderId?: number;
    /**
     * Query Draft ID
     */
    queryDraftId?: number;
    /**
     * title
     */
    title?: string;
    /**
     * description
     */
    description?: string;
    /**
     * TypeFoem URL
     */
    questionUrl?: string;
    /**
     * Header followupUrl
     */
    followupUrl?: string;
    /**
     * Survey Type
     */
    headerType?: SurveyHeaderRecordItem.headerType;
    /**
     * Header Active?
     */
    active?: boolean;
    /**
     * Questions
     */
    questions?: {
        data?: Array<SurveyQuestionRecordItem>,
    };
}

export namespace SurveyHeaderRecordItem {

    /**
     * Survey Type
     */
    export enum headerType {
        TYPEFORM = 'typeform',
        INTERNAL = 'internal',
    }


}
