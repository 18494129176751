/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { QueryResponseTextRecordItem } from './QueryResponseTextRecordItem';
import type { SurveyQueryQuestionResponseItem } from './SurveyQueryQuestionResponseItem';

/**
 * Query Response Item for Display
 */
export type QueryInteractionResponseItem = {
    /**
     * Attachment ID
     */
    attachmentId?: number;
    /**
     * Attachment Name (filename)
     */
    attachmentName?: string;
    /**
     * Attachment Description
     */
    attachmentDesc?: string;
    /**
     * Attachment Description
     */
    attachmentType?: QueryInteractionResponseItem.attachmentType;
    /**
     * API Response Version
     */
    _ver: number;
    /**
     * Record Unique ID
     */
    id: number;
    /**
     * Query Unique ID
     */
    queryId?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created: number;
    /**
     * User Type ID
     */
    userType: number;
    /**
     * Display for question language
     */
    languageLabel?: string;
    /**
     * Response Text
     */
    text: string;
    /**
     * Flag for response translated
     */
    translated: boolean;
    /**
     * Is this an expert response?
     */
    expert: boolean;
    /**
     * Is this an expert response?
     */
    isExpert?: boolean;
    /**
     * Status
     */
    type: QueryInteractionResponseItem.type;
    /**
     * Status
     */
    responseType?: QueryInteractionResponseItem.responseType | null;
    /**
     * User Id
     */
    userId?: number;
    /**
     * QueryExpertId
     */
    queryExpertId?: number;
    /**
     * patient Document Id
     */
    patientDocumentId?: number;
    /**
     * Formatted Date/Time String
     */
    submitted: string;
    /**
     * Display Name of Author
     */
    displayName: string;
    /**
     * Avatar of Author
     */
    avatar?: string;
    /**
     * Translations for this response. Maybe empty array.
     */
    translations?: {
        data: Array<QueryResponseTextRecordItem>,
    };
    /**
     * Questions
     */
    questions?: {
        data?: Array<SurveyQueryQuestionResponseItem>,
    };
}

export namespace QueryInteractionResponseItem {

    /**
     * Attachment Description
     */
    export enum attachmentType {
        IMAGE = 'image',
        PDF = 'pdf',
        UNKNOWN = 'unknown',
    }

    /**
     * Status
     */
    export enum type {
        RESPONSE = 'response',
        INFO = 'info',
        CSRINFO = 'csrinfo',
        CSRHOLD = 'csrhold',
        CSRUPDATE = 'csrupdate',
        CSRUPDATEACCEPT = 'csrupdateaccept',
        CSRUPDATEDECLINE = 'csrupdatedecline',
        PENDEDEXPERT = 'pendedexpert',
        REJECTEDEXPERT = 'rejectedexpert',
        REJECTEDEXPERTRESPONSE = 'rejectedexpertresponse',
        RETRACTEDEXPERT = 'retractedexpert',
        ATTACHMENT = 'attachment',
        ATTACHMENT_REMOVED = 'attachment_removed',
        ATTACHMENT_INHERITED = 'attachment_inherited',
    }

    /**
     * Status
     */
    export enum responseType {
        CSRNOTE = 'csrnote',
        SUPPRESSED = 'suppressed',
        ATTACHMENT = 'attachment',
        RESPONSE = 'response',
        CLARIFY = 'clarify',
        CLINICAL = 'clinical',
        SALUTATION = 'salutation',
    }


}
