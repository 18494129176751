<comments>
	Currenlty the Question Builder is static (with complete ui elements). Functinality will work dynamically, once  it is integrated with API.
</comments>

<template>
  <action :action="action" showText v-on="$listeners">
    <template v-slot:default="{ show }">
  <v-expand-transition>
  <div v-if="show && !event" class="d-flex" :class="noQuestionPresent ? 'justify-center' : 'justify-end'">
    <v-btn text @click.stop="popupEditorDialog = true" :class="{'primary-color' : noQuestionPresent}">{{ noQuestionPresent ? 'Continue Submission' : 'Edit'}}</v-btn>
    <v-dialog max-width="880" scrollable v-model="popupEditorDialog" persistent
      @keydown.esc="popupEditorDialog = false; $emit('close-builder')">
      <v-card :loading="cardLoading">
        <v-card-title>
          <v-toolbar flat dense>
            <v-toolbar-title class="primary--text">
              {{ $t('headers.questionBuilder') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="popupEditorDialog = false; $emit('close-builder')">
              <v-icon color="primary">$close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pb-0 card-content">
          <v-row class="mt-0">
            <v-col cols="12" md="4" class="builder-left-section pb-0">
              <div class="scrollable-left-panel d-flex flex-column">
                <div class="d-flex align-center py-2">
                <h2 class="black--text d-flex align-center font-weight-bold text-subtitle-1">{{
    $t('headers.questionList') }}<v-icon color="primary" size="large" class="mx-2"
                    @click="showInfoContent = !showInfoContent">$infoBubble</v-icon><span v-if="questionLimitReached"
                    class="error--text text-caption">{{ $t('captions.limitReached') }}</span></h2>
                <v-spacer></v-spacer><v-icon color="primary" class="add-icon align-self-baseline" size="extralarge"
                  :disabled="questionLimitReached || hideAddNewIcon" @click="addNewQuestion()">$addMore</v-icon>
              </div>
              <v-expand-transition>
                <p class="pa-2 mb-2 ps-0 info-content black--text" v-if="showInfoContent"
                  v-html="$t('messages.questionBuilderInfo')"></p>
              </v-expand-transition>
              <div class="question-panel">
                <draggable v-model="questionList" @change="updateQueryOrderRecords">
                  <div v-for="question in questionList" :key="question.id">
                    <div class="question-item-wrapper d-flex align-center">
                      <div v-if="isOpenQuestionTrash(question)"
                        class="question-item-content outline pa-4 my-3 active-question">
                        <p class="white--text text-center">{{ $t('messages.deleteQuestion') }}</p>
                        <div class="trash-actions d-flex justify-space-around">
                          <v-btn size="small" color="white" outlined class="font-weight-regular"
                            @click="question.openTrash = false">
                            {{ $t('captions.cancel') }}
                          </v-btn>
                          <v-btn color="white" outlined class="font-weight-regular ms-4"
                            @click="deleteSurveyQuestion(question)">
                            {{ $t('captions.delete') }}
                          </v-btn>
                        </div>
                      </div>
                      <div v-else class="question-item-content outline pa-2 my-3 cursor-pointer"
                        :class="{ 'active-question': question.isActive }" @click="saveQuestion(question.id);">
                        <p class="mb-0 black--text">{{ question.order }}. {{
                          question.questionTypeLabel
                        }}</p>
                        <p class="mb-0">{{ truncateQuestionText(question.text) }}</p>
                      </div>
                      <v-btn icon v-if="!question.openTrash && showTrashIcon" @click="question.openTrash = true">
                        <v-icon color="error" size="small">$trash</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </draggable>
                <div v-if="!noQuestionPresent && !isActiveQuestion && openNewTrashQuestion" class="question-item-content outline pa-4 my-3 active-question">
                  <p class="white--text text-center">{{ $t('messages.deleteQuestion') }}</p>
                  <div class="trash-actions d-flex justify-space-around">
                    <v-btn size="small" color="white" outlined class="font-weight-regular"
                      @click="openNewTrashQuestion = false">
                      {{ $t('captions.cancel') }}
                    </v-btn>
                    <v-btn color="white" outlined class="font-weight-regular ms-4"
                      @click="deleteNewQuestion">
                      {{ $t('captions.delete') }}
                    </v-btn>
                  </div>
                </div>
                <div class="d-flex void-question-wrapper py-3 align-center" v-else-if="noQuestionPresent || !isActiveQuestion">
                  <p class="d-flex align-center mb-0 white--text ps-2">
                    {{ noQuestionPresent ? '1' : questionList ? questionList?.length  + 1 : '1' }}. New Question</p>
                  <v-btn icon v-if="!noQuestionPresent && !isActiveQuestion" @click="openNewTrashQuestion = true">
                    <v-icon color="error" size="small">$trash</v-icon>
                  </v-btn>
                </div>
              </div>
              </div>
            </v-col>
            <v-col cols="12" md="8" class="builder-right-section">
              <QuestionBuilderActionContent ref="builderContentComponent" :configItems="builderConfigItems"
                :getConfigValues="fetchConfigValues" :activeQuestion="selectedQuestion" :questionType="questionType"
                :requiredTextError="requiredTextError" :requiredTypeError="requiredTypeError" :validateChecks="hasLabelError"
                @create-question="storeQuestionText" @reverse-error-state="hasLabelError = false" @selected-option="fetchQuestionId" :questionList="questionList">
              </QuestionBuilderActionContent>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex px-6">
          <o-btn color="primary" class="px-4 font-weight-regular" :disabled="(noQuestionPresent && showFinishBtn)" @click="finishQuestion()">
            {{ showUpdateBuilder ? 'UPDATE QUESTIONS' : 'FINISH QUESTIONS' }}
          </o-btn>
          <v-spacer></v-spacer>
          <span v-if="saveLoading">Saving.. <v-icon color="dark"
              class="black--text pe-2 ms-2">$saveRotateArrow</v-icon></span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</v-expand-transition>
  </template>
  </action>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
import Action from "./Action.vue";
import { ChatBotAction, ChatBotEvent, TypeformService, SurveyService, SurveyHeaderRecordItem, SurveyQuestionRecordItem, QueryResponseTextRecordItem } from "@/service";
import draggable from 'vuedraggable'
import OBtn from "@/framework/OBtn.vue";
import { UserType } from "@/config";
import QuestionBuilderActionContent from "./QuestionBuilderActionContent.vue";
import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";
import { QueryHeaderType } from "@/store/query/types";


const { mapGetters } = createHelpers<RootStoreInterface>();
const getters = mapGetters(["user"]);

export type ChatBotClientData = Record<string, any>;

interface QuestionRecordItem extends SurveyQuestionRecordItem {
  openTrash?: boolean;
  isActive?: boolean;
}


export default (Vue as VueConstructor<
  Vue & {
    $refs: {

    };
  }
>).extend({
  name: "QuestionBuilderAction",
  components: {
    Action,
    draggable,
    OBtn,
    QuestionBuilderActionContent
  },
  props: {
    action: {
      type: Object as PropType<ChatBotAction>,
      required: false
    },
    queryDraftId: {
      type: Number,
      default: 0
    },
    showUpdateBuilder: {
      type: Boolean,
      default: false
    },
    query: {
      type: Object as PropType<QueryHeaderType>,
      required: false
    }
  },
  data: () => ({
    event: undefined as ChatBotEvent | undefined,
    text: "",
    windowHeight: 0,
    popupEditorDialog: true,
    cardLoading: false,
    openTrashSection: false,
    showInfoContent: false,
    builderConfigItems: [],
    selectedQuestion: {} as QueryResponseTextRecordItem,
    questionList: [] as QuestionRecordItem[] | undefined,
    surveyQuestionData: {} as QuestionRecordItem,
    draftId: 0,
    saveLoading: false,
    questionText: "",
    surveyRecord: {} as SurveyHeaderRecordItem,
    currentSurveyId: 0,
    newSurveyRecord: {} as SurveyHeaderRecordItem,
    questionId: 0,
    requiredTextError: false,
    requiredTypeError: false,
    fetchConfigValues: false,
    questionConfigPayload: {},
    openNewTrashQuestion: false,
    duplicateQuestionId: 0,
    questionType: {} as Record<number, string>,
    ratingCount: 10,
    opinionCount: 10,
    hasLabelError: false
  }),
  computed: {
    ...getters,
    minCount(): number {
      return this.action?.minimum ?? 1;
    },
    smallScreen(): boolean {
      return this.windowHeight < 400;
    },
    questionLimitReached(): boolean {
      return (this.questionList?.length ?? 0) >= 20;
    },
    isActiveQuestion(): boolean {
      return this.questionList !== undefined && this.questionList.some(question => question.isActive);
    },
    showTrashIcon(): boolean {
      return this.questionList ? this.questionList.length >= 2 : false;
    },
    hideAddNewIcon(): boolean {
      return !this.questionText || !this.questionId
    },
    showFinishBtn(): boolean {
      return (this.questionText && this.questionId) ? false : true;
    },
    isCsr(): boolean {
      return this.user?.userType == UserType.CSR;
    },
    noQuestionPresent(): boolean {
      return this.questionList?.length == 0;
    }
  },
  methods: {
    async initSurvey() {
      this.cardLoading = true;
      const userId = this.action.clientData?.queryUserId ?? this.query.userId;
      const payload = this.draftId 
        ? { queryDraftId: this.draftId, userId } 
        : { queryId: this.query.queryId, userId };
      try {
        const r = await SurveyService.getSurveyByQueryId(payload);
        this.surveyRecord = r;
        this.currentSurveyId = this.surveyRecord.id as number;
        this.load()
      } catch (e) {
        console.log(e);
      }
      this.cardLoading = false;
    },
    async load() {
      this.cardLoading = true;
      const payload = this.draftId 
        ? { surveyHeaderId: this.currentSurveyId, queryDraftId: this.draftId, pageSize: 20 } 
        : { surveyHeaderId: this.currentSurveyId, queryId: this.query.queryId, pageSize: 20 };
      try {
        const r = await SurveyService.getSurveyQuestionList({ surveyHeaderId: this.currentSurveyId, queryDraftId: this.draftId, pageSize: 20 });
        this.questionList = r?.data?.map((question) => ({
          ...question,
          openTrash: false,
          isActive: false
        }))


        if (this.questionList && this.questionList.length) {
          let activeId = this.questionList[this.questionList.length - 1].id as number;
          this.updateActiveQuestion(activeId);
        }

      } catch (e) {
        console.log(e);
      }
      this.cardLoading = false;
    },
    async saveQuestion(preActiveId?: number | undefined) {
      this.fetchConfigData();
      if(this.hasLabelError) {
        return;
      }
      if (!this.checkRequiredError(preActiveId)) {
        return;
      };
      if (this.isActiveQuestion) {
        const activeQuestion = this.questionList?.find(question => {
          if (question.isActive) {
            return question;
          }
        })
        if (preActiveId) this.updateActiveQuestion(preActiveId);
        this.updateQuestion(activeQuestion?.id as number);
        return;
      }

      if (preActiveId) this.updateActiveQuestion(preActiveId);
      this.cardLoading = true;
      this.saveLoading = true;
      let questionOrder = this.questionList?.length ? this.questionList.length + 1 : 1;
      const sanitizeConfigPayload = JSON.stringify(this.questionConfigPayload);

      const userId = this.action.clientData?.queryUserId ?? this.query.userId;

      this.surveyQuestionData = {
        userId: userId,
        surveyHeaderId: this.currentSurveyId,
        order: questionOrder,
        text: this.questionText,
        questionTypeId: this.questionId ?? 1,
        questionWhen: SurveyQuestionRecordItem.questionWhen.QUESTION,
        configuration: sanitizeConfigPayload,
        openTrash: false,
        isActive: false
      } as QuestionRecordItem;

      let itemData = Object.assign({}, this.surveyQuestionData);

      try {
        const r = await SurveyService.createSurveyQuestion({
          requestBody: itemData
        });

        const modifiedRes = r as QuestionRecordItem;
        modifiedRes.openTrash = false;
        modifiedRes.isActive = false;

        this.questionList?.push(modifiedRes);

        // should  display new created question through  add more icon
        // if(!preActiveId) this.updateActiveQuestion(modifiedRes.id)

      } catch (e) {
        console.log(e);
      }

      this.clearError();
      this.saveLoading = false;
      this.cardLoading = false;
    },
    async getActiveQuestion(id: number | undefined) {
      this.cardLoading = true;
      this.updateActiveQuestion(id as number);

      try {
        const r = await SurveyService.getSurveyQuestionById({ id: id as number });
        this.selectedQuestion = r;
      } catch (e) {
        console.log(e);
      }
      this.cardLoading = false;
    },
    async updateQuestion(id: number | undefined) {
      if (!this.checkRequiredError()) {
        return;
      };

      const sanitizeConfigPayload = JSON.stringify(this.questionConfigPayload);


      this.cardLoading = true;
      this.saveLoading = true;
      let updatedPaylod = {
        text: this.questionText,
        questionTypeId: this.questionId ?? 1,
        configuration: sanitizeConfigPayload,

      } as QuestionRecordItem;

      const activeQuestion = this.questionList?.find(question => question.id === id);

      if (activeQuestion) {
        // Update question value through state
        activeQuestion.text = updatedPaylod.text;
        activeQuestion.questionTypeId = updatedPaylod.questionTypeId;
        activeQuestion.configuration = sanitizeConfigPayload;

      }
      try {
        const r = await SurveyService.updateSurveyQuestion({ id: id ?? 0, requestBody: updatedPaylod });
      } catch (e) {
        console.log(e);
      }

      this.clearError();
      this.saveLoading = false;
      this.cardLoading = false;
    },
    async deleteSurveyQuestion(question: QuestionRecordItem) {
      const id = question.id;
      this.cardLoading = true;
      const deleteIndex = this.questionList?.findIndex(question => question.id === id) as number;

      // adjacentID to show next active question
      let adjacentId: number | undefined;
      if (this.questionList) {
        if (deleteIndex === 0) {
          adjacentId = this.questionList[1]?.id;
        } else if (this.questionList.length > 1) {
          adjacentId = this.questionList[deleteIndex - 1]?.id;
        }
      }

      try {
        await SurveyService.deleteSurveyQuestion({ id: id as number });
        this.questionList = this.questionList?.filter(question => question.id != id);
        this.updateQueryOrderRecords();
        question.isActive = false;
        this.selectedQuestion = {};
        this.updateActiveQuestion(adjacentId)

      } catch (e) {
        console.log(e);
      }
      this.cardLoading = false;
    },
    deleteNewQuestion() {

      const adjacentId = this.questionList ? this.questionList[this.questionList.length - 1]?.id : 0;

      this.updateActiveQuestion(adjacentId);
      this.openNewTrashQuestion = false;
    },
    async initQuestionBuilder() {
      this.cardLoading = true;
      try {
        const r = await TypeformService.getTypeFormConfig({});
        this.builderConfigItems = r.builderConfig;

        // init question type from builder-config
        this.questionType = this.builderConfigItems.reduce((acc: Record<number, string>, item: any) => {
          acc[item.id] = item.text;
          return acc;
        }, {} as Record<number, string>);

      } catch (e) {
        console.log(e);
      }
      this.openNewTrashQuestion = false;
      this.cardLoading = false;
    },
    updateActiveQuestion(id: number | undefined) {
      this.cardLoading = true;

      this.questionList?.map(question => {
        if (question.id == id) {
          question.isActive = true;
          this.selectedQuestion = question;
        } else {
          question.isActive = false;
        }
      })

      this.cardLoading = false;
    },
    async updateQueryOrderRecords() {
      this.questionList?.forEach((question, index) => {
        question.order = index + 1;
      });
      const orderPayload = this.questionList?.map(question => ({
        id: question.id,
        order: question.order,
      })) || []

      try {
        const r = await SurveyService.updateSurveyQuestionOrder({
          requestBody: orderPayload
        });
        this.selectedQuestion = r;
      } catch (e) {
        console.log(e);
      }
      this.cardLoading = false;
    },
    onResize(): void {
      // TODO This Value is treated differently by mobile safari and mobile chrome. Try to standardize on it
      this.windowHeight = window.innerHeight;
    },
    truncateQuestionText(text: string | undefined) {
      let sanitizeText = "";
      if (text && text.length > 50) {
        sanitizeText = text.slice(0, 50) + '...';
        return sanitizeText;
      }
      return text;
    },
    addNewQuestion() {
      this.fetchConfigData();
      if (this.hasLabelError) {
        return;
      }
      this.duplicateQuestionId = this.questionId;
      this.selectedQuestion = {};

      if (this.isActiveQuestion) {
        const activeQuestion = this.questionList?.find(question => question.isActive);
        if (activeQuestion) {
          activeQuestion.isActive = false;
        }
        this.fetchConfigData();
        this.updateQuestion(activeQuestion?.id);
        return;
      }
      this.saveQuestion();

    },
    isOpenQuestionTrash(question: QuestionRecordItem): boolean {
      return question.openTrash as boolean && this.questionList?.length as number >= 2;
    },
    storeQuestionText(text: string) {
      this.questionText = text;
      if (this.questionText) {
        this.requiredTextError = false;
      }
    },
    fetchQuestionId(questionTypeText: string): number | undefined {
      const questionTypeEntry = Object.entries(this.questionType).find(([key, value]) => value === questionTypeText);
      if (questionTypeEntry) {
        this.questionId = Number(questionTypeEntry[0]);
        this.requiredTypeError = false;
      } else {
        this.questionId = 0;
      }
      return undefined;
    },
    checkRequiredError(preActiveId?: number) {
      let shouldContinue = true;
      if (!this.questionText && !this.questionId && preActiveId) {
        this.updateActiveQuestion(preActiveId);
        shouldContinue = false;
      }
      else if (!this.questionText || !this.questionId) {
        if (!this.questionText) {
          this.requiredTextError = true;
        }
        if (!this.questionId) {
          this.requiredTypeError = true;
        }
        shouldContinue = false;
      }
      return shouldContinue;
    },
    clearError() {
      this.requiredTextError = false;
      this.requiredTypeError = false;
    },
    async finishQuestion() {
      this.fetchConfigData();
      if (this.hasLabelError) {
        return;
      }
      if (!this.questionText && !this.questionId && this.questionList && this.questionList?.length >= 1) {
        this.popupEditorDialog = false;
        this.event = {
          type: "respond",
          ts: Date.now(),
          clientData: this.action.clientData,
          responses: [this.date ?? ""]
        };
        this.$emit('update-question-summary');
        this.$emit("callback", this.event);
      } else {
        if (!this.checkRequiredError(1)) {
          return;
        };
        await this.saveQuestion();
        this.popupEditorDialog = false;
        this.event = {
          type: "respond",
          ts: Date.now(),
          clientData: this.action.clientData,
          responses: [this.date ?? ""]
        };
        this.$emit('update-question-summary');
        this.$emit('close-builder');
        this.$emit("callback", this.event);
      }

    },
    fetchConfigData() {
      const builderContentComponent = this.$refs.builderContentComponent as Vue & { getConfigData: () => any };
      this.hasLabelError = false;

      if (builderContentComponent) {
        const data = builderContentComponent.getConfigData();
        let processedData = [];

        const validation = data.find(item => item.validation)?.validation;
        const choices = data.find(item => item.choices)?.choices;
        const label = data.find(item => item.label)?.label;
        const selectedRating = data.find(item => item.selectedRating)?.selectedRating;
        const maxOpinionScale = data.find(item => item.maxOpinionScale)?.maxOpinionScale;
        this.questionConfigPayload = data;

        const finalQuestionId = this.questionId ?? this.duplicateQuestionId;


        switch (finalQuestionId) {
          case 1:
          case 5:
            if (validation !== undefined && choices !== undefined) {
              processedData.push({ validation, choices });
            } else {
              processedData.push({ validation });
            }
            break;
          case 2:
          case 3:
          case 4:
            if (validation !== undefined) {
              processedData.push({ validation });
            }
            break;
          case 6:
            if (this.isCsr) {
              if (validation !== undefined && selectedRating !== undefined) {
                processedData.push({ validation, selectedRating });
                this.ratingCount = selectedRating;
              } else {
                processedData.push({ validation });
              }
            } else {
              if (validation !== undefined) {
                processedData.push({ validation });
              }
            }

            break;
          case 7:
            if(!label.min || !label.max || !label.median ) {
              this.hasLabelError = true;
            }
            if (validation !== undefined && label !== undefined) {
              if (this.isCsr && maxOpinionScale !== undefined) {
                processedData.push({ validation, label, maxOpinionScale });
                this.opinionCount = maxOpinionScale;
              } else {
                processedData.push({ validation, label });
              }
            } else {
              processedData.push({ validation });
            }
            break;
          default:
            console.warn(`Unexpected questionId: ${this.questionId}`);
        }

        this.questionConfigPayload = processedData;
      }
    },
  },
  watch: {
    questionText(newVal: string) {
      if (newVal) {
        this.requiredTextError = false;
      }
    },
    questionTypeId(newVal: string | number) {
      if (newVal) {
        this.requiredTypeError = false;
      }
    },
    isActiveQuestion(newVal: boolean) {
      if (!newVal) {

      }
    }

  },
  mounted() {
    this.draftId = this.$route.query["draftId"] ? +this.$route.query["draftId"] : this.queryDraftId;
    this.initSurvey();
    this.initQuestionBuilder();
  },
});
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 6px;
  border-radius: 4px;
  display: absolute;
  right: 8px
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

:deep(.v-card__text) {
  min-height: 400px;
  max-height: 100%;
}

:deep(.v-card__actions) {
  box-shadow: 8px -23px 16px -17px rgba(217, 215, 215, 0.45);
}

:deep(.v-toolbar__content) {
  padding-left: 0;
}

.row {
  height: 100%;
}

.builder-left-section {
  border-right: 2px solid #e1e1e1;
  position: relative;

    .scrollable-left-panel {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 12px;
      left: 13px;
    }
}

.void-question-wrapper p,
.question-item-content {
  background-color: var(--v-primary-base);
  max-width: 100%;
  min-height: 50px;
  border-radius: 10px;
  width: calc(100% - 36px);
}

.question-item-content.outline {
  background-color: white;
  border: 1px solid var(--v-primary-base);


  p:nth-child(2) {
    color: rgba(0, 0, 0, 0.6);
  }
}

.active-question.question-item-content.outline {
  background-color: var(--v-primary-base);
  color: #ffffff;

  P {
    color: #ffffff !important;
  }

  .v-btn {
    height: 28px;
    min-width: 50px;
    padding: 0 12px;
    font-size: 0.75rem;
  }
}

.question-panel {
  overflow-y: auto;
}

:deep(.v-list) {
  border-top: 2px solid var(--v-primary-base);
  border-bottom: 2px solid var(--v-primary-base);

}

.info-content {
  border-bottom: 3px solid var(--v-primary-base);
}

.add-icon {
  font-size: 28px;
}

.cursor-pointer {
  cursor: pointer;
}

.v-dialog__content {
  top: 30px;
}

:deep(.v-dialog) {
  height: 100%;
}


 .builder-right-section {
  max-height: 100%;
  overflow-y: auto;
}

.card-content {
  overflow-y: clip !important;
}
</style>