<comments>
  Can be used to show any custom error message with timeout
</comments>

<template>
  <v-fade-transition>
    <span v-if="visible" class="error--text">{{ text }}</span>
  </v-fade-transition>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    timer: null as any,
    visible: false,
    text: ""
  }),
  props: {
    // To show error label set value to true
    value: {
      type: Boolean,
      default: false
    },
    // Set error message
    message: {
      type: String,
      default: ""
    },
    // Set how log it should stay visible (In miliseconds).
    // Set 0 to keep it permanently visible.
    timeout: {
      type: Number,
      default: 0
    }
  },
  methods: {
    show() {
      // Clear previous timer if it's already running
      if (this.timer) {
        this.clear();
      }
      // Check if message is not empty
      if (this.message && this.message.length > 0) {
        this.visible = true;
        this.text = this.message;
        // Reset value(v-model) to false immediately after showing label
        this.$emit("input", false);

        // Check if timeout is not 0
        if (this.timeout <= 0) {
          return;
        }
        // Set timer
        this.timer = setTimeout(() => {
          this.clear();
        }, this.timeout);
      }
    },
    hide() {
      this.clear();
    },
    clear() {
      this.visible = false;
      this.text = "";

      // Reset value to false
      if (this.value) {
        this.$emit("input", false);
      }

      // If timer is active then clear it
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    }
  },
  watch: {
    value(val: boolean) {
      if (val) {
        this.show();
      }
    }
  }
});
</script>
