<template>
  <action :action="action" showText v-on="$listeners" v-resize="onResize">
    <template v-slot:default="{ show }">
      <v-expand-transition>
        <div v-if="show && !event"  class="d-flex" :class="noHistory ? 'justify-center' : 'justify-end'">
          <!-- SHow indication if there is text or not and ake ot clickable to activate the dialog -->
          <v-btn text @click.stop="popupEditorDialog = true" :class="{'primary-color': noHistory}"> {{ noHistory ? 'Continue Submission' : 'Edit'}}</v-btn>
          <v-dialog
            max-width="800"
            :fullscreen="smallScreen"
            scrollable
            v-model="popupEditorDialog"
            persistent
            @keydown.esc="popupEditorDialog = false"
          >
            <v-card>
              <v-toolbar flat dense>
                <v-toolbar-title v-text="action.label || 'Response'" />
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn icon text id="dismiss" @click="popupEditorDialog = false">
                    <v-icon>$close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text
                style="border-top:1px solid var(--v-primary-base);border-bottom:1px solid var(--v-primary-base);"
              >
                <v-textarea
                  solo
                  flat
                  auto-grow
                  no-resize
                  v-model="text"
                  class="ma-2"
                  hide-details
                  dense
                  :placeholder="placeHolderComp"
                  @keydown.enter.shift.exact.prevent="enter()"
                  id="response"
                  persistent-hint
                />
              </v-card-text>
              <v-card-actions>
                <div style="font-size: 10px;" v-text="$t('discussion.response.shiftEnter')" />
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" small icon id="submit" class="buttonClass ml-4 mb-2" @click="enter()"
                      ><v-icon color="primary" v-text="'$send'"></v-icon
                    ></v-btn> </template
                  ><span v-text="$t('discussion.response.send')"
                /></v-tooltip>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-expand-transition>
    </template>
  </action>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
import Action from "./Action.vue";
import { VTextField } from "vuetify/lib";
import { ChatBotAction, ChatBotEvent } from "@/service";

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      field: typeof VTextField;
    };
  }
>).extend({
  name: "LongTextActionComponent",
  components: {
    Action
  },
  props: {
    action: {
      type: Object as PropType<ChatBotAction>,
      required: true
    }
  },
  data: () => ({
    event: undefined as ChatBotEvent | undefined,
    text: "",
    windowHeight: 0,
    popupEditorDialog: true 
  }),
  computed: {
    minCount(): number {
      return this.action?.minimum ?? 1;
    },
    smallScreen(): boolean {
      return this.windowHeight < 400;
    },
    placeHolderComp() {
      return this.action.hint ? this.action.hint : this.$t('discussion.response.startTyping');
    },
    noHistory(): boolean {
      return this.action.clientData.history ? false : true;
    }
  },
  methods: {
    onResize(): void {
      // TODO This Value is treated differently by mobile safari and mobile chrome. Try to standardize on it
      this.windowHeight = window.innerHeight;
    },
    enter(): void {
      if (this.text.trim().length >= this.minCount) {
        this.popupEditorDialog = false;
        this.event = {
          type: "respond",
          ts: Date.now(),
          // action: this.action,
          clientData: this.action.clientData,
          responses: [this.text]
        };
        this.$emit("callback", this.event);
      }
    }
  },
  created() {
    if (this.action.responses?.length) {
      this.text = this.action.responses[0];
    }
  }
});
</script>
<style lang="scss" scoped>
.primary-color {
  color: var(--v-primary-base) !important;
}
</style>
