<template>
  <div class="action">
    <v-expand-transition>
      <div v-if="showText && messages.length">
        <component
          v-for="(m, index) in messages"
          :key="index"
          bot
          :message="m"
          :is="m.type == 'banner' ? 'BannerMessage' : 'TextMessage'"
          @actionClick="$emit('actionClick', $event)"
        />
      </div>
    </v-expand-transition>
    <div v-if="typing || showTyping" class="sc-message--content received">
      <typing-indicator />
    </div>
    <slot :show="showAction" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import TypingIndicator from "../Messages/TypingMessage.vue";
import TextMessage from "../Messages/TextMessage.vue";
import BannerMessage from "../Messages/BannerMessage.vue";
import { ChatBotMessage, ChatBotMessageContainer } from "@/service";

export default Vue.extend({
  name: "ChatBotActionComponent",
  components: {
    TypingIndicator,
    TextMessage,
    BannerMessage
  },
  props: {
    action: {
      type: Object as PropType<ChatBotMessageContainer>,
      required: false
    },
    showText: {
      type: Boolean,
      default: true
    },
    showTyping: {
      type: Boolean,
      default: false
    },
    identifier: {
      type: Number,
      default: 0
    },
    wizardMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    timer: null as number | null,
    messages: [] as Array<ChatBotMessage>,
    showAction: false,
    typing: false
  }),
  methods: {
    async show(): Promise<void> {
      if (this.action.messages?.length) {
        for (const msg of this.action.messages) {
          if (!msg.user || !this.wizardMode) {
            if (msg.delay) {
              this.typing = true;
              await this.sleep(msg.delay as number);
              this.typing = false;
            }
            this.$emit("log", msg);
            this.messages.push(msg);
            this.$emit("messageAdded");
          }
        }
      }
      /*
        for (let i = 0; i < this.action.messages.length; i++) {
          if (this.action.messages[i].delay) {
            this.typing = true;
            await this.sleep(this.action.messages[i].delay as number);
            this.typing = false;
          }
          this.$emit("log", this.action.messages[i]);
          this.messages.push(this.action.messages[i]);
          this.$emit("messageAdded");
        }
        
      }
      */
      this.showAction = true;
      // await this.$nextTick();
      this.$emit("messageAdded", this.action.ts);
    },
    async sleep(ms: number): Promise<void> {
      return new Promise(resolve =>
        setTimeout(() => {
          this.timer = null;
          resolve();
        }, ms)
      );
    }
  },
  created(): void {
    this.$nextTick(() => this.show());
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
});
</script>
