/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DiagnosisRecordItem } from '../models/DiagnosisRecordItem';
import type { meta } from '../models/meta';
import type { SurveyHeaderRecordItem } from '../models/SurveyHeaderRecordItem';
import type { SurveyQuestionRecordItem } from '../models/SurveyQuestionRecordItem';
import type { SurveyQuestionResponseItem } from '../models/SurveyQuestionResponseItem';
import { request as __request } from '../core/request';

export class SurveyService {

    /**
     * Find survey by ID.
     * Returns matching record
     * @result SurveyHeaderRecordItem successful operation
     * @throws ApiError
     */
    public static async getSurveyById({
        id,
        inclQuestions = true,
    }: {
        /** Record ID **/
        id: number,
        /** Include Questions **/
        inclQuestions?: boolean,
    }): Promise<SurveyHeaderRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/survey/${id}`,
            query: {
                'inclQuestions': inclQuestions,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Survey Header.  Update an existing record
     * Survey Header.  Update a record based on data given
     * @result SurveyHeaderRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateSurveyHeader({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Survey Header object that needs to be updated **/
        requestBody: SurveyHeaderRecordItem,
    }): Promise<SurveyHeaderRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/survey/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Delete an existing survey Header
     * Delete an existing survey Header
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deleteSurveyHeader({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/survey/${id}`,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find survey question by ID.
     * Returns matching record
     * @result SurveyQuestionRecordItem successful operation
     * @throws ApiError
     */
    public static async getSurveyQuestionById({
        id,
        language,
    }: {
        /** Record ID **/
        id: number,
        /** Find records for specific language. If not specified will use Users Language.  Pass `original` for original language. **/
        language?: string,
    }): Promise<SurveyQuestionRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/survey/question/${id}`,
            query: {
                'language': language,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Survey Question.  Update an existing qrecord
     * Survey Question.  Update a record based on data given
     * @result SurveyQuestionRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateSurveyQuestion({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Survey Question object that needs to be updated **/
        requestBody: SurveyQuestionRecordItem,
    }): Promise<SurveyQuestionRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/survey/question/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Delete an existing survey Question
     * Delete an existing survey Question
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deleteSurveyQuestion({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/survey/question/${id}`,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find survey by Survey, Query, or Draft ID.
     * Returns matching record
     * @result SurveyHeaderRecordItem successful operation
     * @throws ApiError
     */
    public static async getSurveyByQueryId({
        surveyHeaderId,
        queryId,
        queryDraftId,
        userId,
        inclQuestions = true,
        createIfNotFound = true,
    }: {
        /** Find records associated with a given Survey Header **/
        surveyHeaderId?: number,
        /** Find records associated with a given Query  **/
        queryId?: number,
        /** Find records associated with a given Query Draft Id **/
        queryDraftId?: number,
        /** Find records associated with a given User **/
        userId?: number,
        /** Include Questions **/
        inclQuestions?: boolean,
        /** When searching for a Query Draft, specify whether a header should be created if one is not found. **/
        createIfNotFound?: boolean,
    }): Promise<SurveyHeaderRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/survey`,
            query: {
                'surveyHeaderId': surveyHeaderId,
                'queryId': queryId,
                'queryDraftId': queryDraftId,
                'userId': userId,
                'inclQuestions': inclQuestions,
                'createIfNotFound': createIfNotFound,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Survey Header.  Create a new survey header
     * Survey Header.  Creates a record based on data given
     * @result SurveyHeaderRecordItem Successful operation
     * @throws ApiError
     */
    public static async createSurveyHeader({
        requestBody,
    }: {
        /** Survey Header object to be created **/
        requestBody: SurveyHeaderRecordItem,
    }): Promise<SurveyHeaderRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/survey`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Return all questions for a survey header.  Search by survey, query draft, or query id
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getSurveyQuestionList({
        surveyHeaderId,
        queryId,
        queryDraftId,
        language,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records associated with a given Survey Header **/
        surveyHeaderId?: number,
        /** Find records associated with a given Query  **/
        queryId?: number,
        /** Find records associated with a given Query Draft Id **/
        queryDraftId?: number,
        /** Find records for specific language. If not specified will use Users Language.  Pass `original` for original language. **/
        language?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<DiagnosisRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/survey/question/list`,
            query: {
                'surveyHeaderId': surveyHeaderId,
                'queryId': queryId,
                'queryDraftId': queryDraftId,
                'language': language,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Return all answers by question for a query.
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getSurveyResponseList({
        queryId,
        hideCsrData,
        language,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records associated with a given Query  **/
        queryId?: number,
        /** Hide CSR Specific data? **/
        hideCsrData?: boolean,
        /** Find records for specific language. If not specified will use Users Language.  Pass `original` for original language. **/
        language?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<SurveyQuestionResponseItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/survey/response/list`,
            query: {
                'queryId': queryId,
                'hideCsrData': hideCsrData,
                'language': language,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Survey Question.  Create a new survey question
     * Survey Question.  Creates a record based on data given
     * @result SurveyQuestionRecordItem Successful operation
     * @throws ApiError
     */
    public static async createSurveyQuestion({
        requestBody,
    }: {
        /** Survey Question object to be created **/
        requestBody: SurveyQuestionRecordItem,
    }): Promise<SurveyQuestionRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/survey/question`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update Survey Question Order.
     * Update Survey Question.  Update a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async updateSurveyQuestionOrder({
        requestBody,
    }: {
        /** Request body description **/
        requestBody: Array<{
            id?: number,
            order?: number,
        }>,
    }): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/server/survey/question/order`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}