/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Query Search Item for Display
 */
export type QuerySearchDisplayItem = {
    /**
     * API Response Version
     */
    _ver: number;
    /**
     * Record Unique ID for Query Header
     */
    queryId?: number;
    /**
     * Score for this Query
     */
    score?: number;
    /**
     * Why this query got the score it did
     */
    scoreReason?: string;
    /**
     * type
     */
    queryType: QuerySearchDisplayItem.queryType;
    /**
     * Media Type of Query
     */
    mediaType: QuerySearchDisplayItem.mediaType;
    /**
     * Query Status
     */
    status: QuerySearchDisplayItem.status;
    /**
     * Query Status -- Display Only
     */
    displayStatus: QuerySearchDisplayItem.displayStatus;
    /**
     * Diagnosis Name
     */
    diagnosisName: string;
    /**
     * Asker Context from Query Wizard
     */
    contextName?: string;
    /**
     * Unix Timestamp - To Seconds Precision - For index ordering and display
     */
    sortTime: number;
    /**
     * Formatted Sort Time
     */
    displayTime: string;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    inviteTime?: number;
    /**
     * Formatted Meeting Time
     */
    inviteTimeDisplay?: string;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    meetingTime?: number;
    /**
     * Formatted Meeting Time
     */
    meetingTimeDisplay?: string;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    closeTime?: number;
    /**
     * Formatted Close Time
     */
    closeTimeDisplay?: string;
    /**
     * Query Subject - TBD
     */
    subject?: string;
    /**
     * SurveyStatus - pre|post|<empty>
     */
    surveyStatus?: string;
    /**
     * Submitter Display Name
     */
    displayName: string;
    /**
     * User Avatar - TBD
     */
    avatar?: string;
    /**
     * User Type ID
     */
    userType: number;
    /**
     * User Type Display
     */
    userTypeDisplay: string;
    /**
     * owner Country
     */
    country?: string;
    /**
     * owner state
     */
    state?: string;
    /**
     * User Status on this Query
     */
    userStatus?: string;
    /**
     * UUID for the participation record
     */
    participantId?: string;
    /**
     * Unix Timestamp - To Seconds Precision -
     */
    lastViewed?: number;
    /**
     * User access to this record is because of a Team they are on
     */
    isTeam?: boolean;
    /**
     * Unix Timestamp - To Seconds Precision -
     */
    notified?: number;
    /**
     * Query has been updated in this user's view
     */
    isUpdated: boolean;
    /**
     * Query has new responses in user's view
     */
    isNewResponses?: boolean;
    /**
     * Users is an expert in this view
     */
    isExpert: boolean;
    /**
     * Users has a pending invitation
     */
    isInvited: boolean;
    /**
     * Query Preview
     */
    previewText: string;
    /**
     * Submitter is anonymized (and teammates)
     */
    isBlindedUser: boolean;
    /**
     * Experts are anonymized
     */
    isBlindedExperts?: boolean;
    /**
     * gender
     */
    gender: QuerySearchDisplayItem.gender;
    /**
     * Age Magnitude
     */
    ageMagnitude?: number;
    /**
     * Age Unit
     */
    ageUnit: QuerySearchDisplayItem.ageUnit;
    /**
     * Number of responses from other than the submitter
     */
    responseCount: number;
    /**
     * Number of participants other than the submitter
     */
    participantCount: number;
    /**
     * question count in query
     */
    questionCount?: number;
    /**
     * Plan Name
     */
    plan?: string;
    /**
     * Plan ID
     */
    planId?: number;
    /**
     * Grant Name
     */
    grant?: string;
    /**
     * Grant ID
     */
    grantId?: number;
    /**
     * Pool ID
     */
    poolId?: number;
    /**
     * User ID
     */
    userId?: number;
    /**
     * Diagnosis ID
     */
    diagnosisId?: number;
    /**
     * Diagnosis Group ID
     */
    diagnosisGroupId?: number;
    /**
     * Diagnosis Domain ID
     */
    diagnosisDomainId?: number;
    /**
     * Flag for CSR Quality Review
     */
    csrReview?: boolean;
    /**
     * Aggregated Plan/Pool Names for Display
     */
    planPoolName?: string;
}

export namespace QuerySearchDisplayItem {

    /**
     * type
     */
    export enum queryType {
        NORMAL = 'normal',
        BLINDED = 'blinded',
        COMMUNAL = 'communal',
        PRIVATE = 'private',
    }

    /**
     * Media Type of Query
     */
    export enum mediaType {
        MESSENGER = 'messenger',
        AUDIO = 'audio',
        VIDEO = 'video',
    }

    /**
     * Query Status
     */
    export enum status {
        OPEN = 'open',
        CLOSED = 'closed',
        ANSWERED = 'answered',
        HELD = 'held',
        PENDING_CHARGE = 'pending_charge',
        CHARGED = 'charged',
        CREATE = 'create',
        CSRHOLD = 'csrhold',
        CANCELLED = 'cancelled',
        PENDING_SURVEY = 'pending_survey',
    }

    /**
     * Query Status -- Display Only
     */
    export enum displayStatus {
        OPEN = 'open',
        CLOSED = 'closed',
        CANCELLED = 'cancelled',
        UNRELEASED = 'unreleased',
    }

    /**
     * gender
     */
    export enum gender {
        UNSPECIFIED = 'unspecified',
        FEMALE = 'female',
        MALE = 'male',
        TRANSMALE = 'transmale',
        TRANSFEMALE = 'transfemale',
        PRETRANSMALE = 'pretransmale',
        PRETRANSFEMALE = 'pretransfemale',
        INDETERMINATE = 'indeterminate',
    }

    /**
     * Age Unit
     */
    export enum ageUnit {
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }


}
