/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Survey Question Record Item
 */
export type SurveyQuestionRecordItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Formatted TimeStamp
     */
    submitted?: string;
    /**
     * Question Owner ID
     */
    userId?: number;
    /**
     * Question Owner ID
     */
    updatedBy?: number;
    /**
     * Survey Header ID
     */
    surveyHeaderId?: number;
    /**
     * Question order position
     */
    order?: number;
    /**
     * Display for question language
     */
    languageLabel?: string;
    /**
     * Text
     */
    text?: string;
    /**
     * Question Configuration
     */
    configuration?: string;
    /**
     * Question Type - MULTIPLE_CHOICE=1, TEXT=2, TRUE_FALSE=3, YES_NO=4, RANKING=5, RATING=6, OPINION_SCALE=7
     */
    questionTypeId?: number;
    /**
     * Question Type Label - Multiple Choice, Short Response, True / False, Yes / No, Ranking, Rating 1-#, Opinion Scale 1-#
     */
    questionTypeLabel?: string;
    /**
     * When Question appears
     */
    questionWhen?: SurveyQuestionRecordItem.questionWhen;
    /**
     * Question Required?
     */
    required?: boolean;
    /**
     * Question Active?
     */
    active?: boolean;
}

export namespace SurveyQuestionRecordItem {

    /**
     * When Question appears
     */
    export enum questionWhen {
        PRE = 'pre',
        QUESTION = 'question',
        POST = 'post',
        FOLLOWUP = 'followup',
    }


}
