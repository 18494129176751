/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { QueryExpertRecordItem } from './QueryExpertRecordItem';
import type { SurveyResponseRecordItem } from './SurveyResponseRecordItem';

/**
 * Survey Response Header Record Item
 */
export type SurveyResponseHeaderRecordItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Formatted Date/Time String
     */
    submitted?: string;
    /**
     * Display Name of Author
     */
    displayName?: string;
    /**
     * Avatar of Author
     */
    avatar?: string;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    started?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Header Owner ID
     */
    userId?: number;
    /**
     * Query Header ID
     */
    queryHeaderId?: number;
    /**
     * surveyHeaderId
     */
    surveyHeaderId?: number;
    /**
     * queryExpertId
     */
    queryExpertId?: number;
    /**
     * Display for question language
     */
    languageLabel?: string;
    /**
     * Survey response status
     */
    status?: SurveyResponseHeaderRecordItem.status;
    expert?: QueryExpertRecordItem;
    /**
     * Responses
     */
    responses?: {
        data?: Array<SurveyResponseRecordItem>,
    };
}

export namespace SurveyResponseHeaderRecordItem {

    /**
     * Survey response status
     */
    export enum status {
        TYPEFORM = 'typeform',
        INTERNAL = 'internal',
    }


}
