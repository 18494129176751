export function scrollParentToChild(parent: HTMLElement, child: HTMLElement): void {
  // Where is the parent on page
  const parentRect = parent.getBoundingClientRect();
  // What can you see?
  const parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth
  };

  // Where is the child
  const childRect = child.getBoundingClientRect();
  // Is the child viewable?
  const isViewable = childRect.top >= parentRect.top && childRect.bottom <= parentRect.top + parentViewableArea.height;

  // if you can't see the child try to scroll parent
  if (!isViewable) {
    // Should we scroll using top or bottom? Find the smaller ABS adjustment
    const scrollTop = childRect.top - parentRect.top;
    const scrollBot = childRect.bottom - parentRect.bottom;
    if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
      // we're near the top of the list
      parent.scrollTop += scrollTop - 12;
    } else {
      // we're near the bottom of the list
      parent.scrollTop += scrollBot + 12;
    }
  }
}

export async function scrollToBottom(element: HTMLElement | null | undefined): Promise<void> {

  if (!element) {
    console.warn('scrollToBottom: Element is null or undefined');
    return;

  }

  // We dont know when the additions will finish rendering, so a slight delay improves that and is imperctible when there is no scroll.
  await new Promise(resolve => setTimeout(resolve, 200));
  element.scroll({ top: element.scrollHeight + 12 /*, behavior: "smooth" */ });
}
