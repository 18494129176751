/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class TypeformService {

    /**
     * Pull config from database and prepare josn for typeform
     * Process JSON and Submit it to typeForm
     * @result any Configuration JSON
     * @throws ApiError
     */
    public static async getTypeFormConfig({
        surveyHeaderId,
        queryId,
    }: {
        /** Find records associated with a given Survey Header **/
        surveyHeaderId?: number,
        /** Find records associated with a given Query  **/
        queryId?: number,
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/typeform/config`,
            query: {
                'surveyHeaderId': surveyHeaderId,
                'queryId': queryId,
            },
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * submits a create form API request typeForm
     * Process JSON and Submit it to typeForm
     * @result any created
     * @throws ApiError
     */
    public static async typeFormCreate({
        requestBody,
    }: {
        /** Request Parameters **/
        requestBody?: {
            payload?: string,
        },
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/typeform/create`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * submits a delete form API request typeForm
     * Process JSON and Submit it to typeForm
     * @result any created
     * @throws ApiError
     */
    public static async typeFormDelete({
        formId,
    }: {
        /** Form Id **/
        formId: string,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/typeform/delete/${formId}`,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * get a form API request typeForm
     * Fetch a Typeform by ID
     * @result any created
     * @throws ApiError
     */
    public static async typeFormGetForm({
        formId,
    }: {
        /** Form Id **/
        formId: string,
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/typeform/${formId}`,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * retrieve form responses from typeform
     * retrieve form responses from typeform
     * @result any created
     * @throws ApiError
     */
    public static async getTypeFormResponses({
        formId,
        surveyResponseKey,
        processResponseToDb = true,
    }: {
        /** Form Id **/
        formId: string,
        /** typeform Response Key single or multiple separated by comma **/
        surveyResponseKey?: string,
        /** Should any responses found be process to the DB or only returned? **/
        processResponseToDb?: boolean,
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/typeform/responses/${formId}`,
            query: {
                'surveyResponseKey': surveyResponseKey,
                'processResponseToDB': processResponseToDb,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
                500: `Error: Update Failed`,
            },
        });
        return result.body;
    }

    /**
     * submits a update form API request typeForm
     * Process JSON and Submit it to typeform
     * @result any created
     * @throws ApiError
     */
    public static async typeFormUpdate({
        formId,
        requestBody,
    }: {
        /** Form Id **/
        formId: string,
        /** Complete Request payload with modified fields **/
        requestBody?: {
            payload?: string,
        },
    }): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/typeform/update/${formId}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}